.ant-table-tbody {
    padding: 4px !important;
}

.ant-table-tbody>tr>td {
    padding: 4px !important;
}

.table-row-light {
    background-color: #ffffff;
}

.table-row-dark {
    background-color: #fbfbfb;
}

/* .ant-table-measure-row {
    display: none !important;
} */